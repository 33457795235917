import {
  Search as SearchIcon,
  Star as StarIcon,
  List as ListIcon,
  CheckCircle as CheckCircleIcon,
  Upload as UploadIcon,
  Settings as SettingsIcon,
  Image as ImageIcon,
  TextFields as TextFieldsIcon,
  RadioButtonChecked as RadioButtonCheckedIcon
} from "@mui/icons-material";
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { useEffect } from "react";
import { fetchFormRequestsApiFormid } from "../../services/formService";
import useFormStore from "../../store/formStore";
import { Box } from "@mui/material";

const FormSidebarcomp = ({ disabled = false }: { disabled?: boolean; }) => {
  // props & state values
  const id = localStorage.getItem("formid");

  const { disabledObj, setDisabledObj, setSelectedInput } = useFormStore();

  const menuItems = [
    {
      text: "Search",
      icon: <SearchIcon />,
      route: "Search"
    },
    {
      text: "Yes/No (Boolean)",
      icon: <CheckCircleIcon />,
      route: "Yes/No(Boolean)"
    },
    {
      text: "FileUpload",
      icon: <UploadIcon />,
      route: "FileUpload"
    },
    {
      text: "Multi-Dropdown",
      icon: <SettingsIcon />,
      route: "MultiDropdown"
    },
    {
      text: "ImagePicker",
      icon: <ImageIcon />,
      route: "ImagePicker"
    },
    {
      text: "Ranking",
      icon: <LeaderboardIcon />,
      route: "Ranking"
    },
    {
      text: "TextFiled",
      icon: <TextFieldsIcon />,
      route: "TextFiled"
    },
    {
      text: "Radio group",
      icon: <RadioButtonCheckedIcon />,
      route: "RadioButtonGroup"
    },
    {
      text: "RatingScale",
      icon: <StarIcon />,
      route: "RatingScale"
    },
    {
      text: "SelectDropdown",
      icon: <ListIcon />,
      route: "SelectDropdown"
    },
  ]

  // callbacks & functions
  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const response = await fetchFormRequestsApiFormid(id || "");
          if (response?.data) {

            setDisabledObj((prev) => ({
              ...prev,
              FileUpload: Boolean(response?.data?.data?.isScoreBased), // Disables the FileUpload field
              Ranking: Boolean(response?.data?.data?.isScoreBased), // Disables the Ranking field
            }));
          }
        } catch (error) {
          console.error("Error fetching form data:", error);
        }
      };

      fetchData();
    }
  }, [id]);

  const handleItemClick = (radioValue: string) => {
    if (!disabledObj[radioValue] && !disabled) {
      setSelectedInput(radioValue);
      if (radioValue === "TextFiled") {
        localStorage.setItem("Headache", "MIDAS");
        return;
      }
      if (radioValue === "RadioButtonGroup") {
        localStorage.setItem("Headache", "Headache");
        return;
      }
    }
  };

  return (
    <Box>
      <Box sx={{
        width: "200px",
        backgroundColor: "var(--neuro-white-text)",
        padding: "10px",
        borderRight: "1px solid var(--neuro-secondary_border)",
        height: "100%",
        overflowY: "auto",
        overflowX: "hidden",
      }}>
        <Box
          sx={{
            width: "100%",
            // height: "761px",
            height: "100%",
            flexShrink: 0,
            padding: 0,
            margin: 0,
          }}
          onClick={(e) => {
            const target = e.target as HTMLElement;
            const menuItem = target.closest("[data-menu-item]");
            if (menuItem) {
              const route = menuItem.getAttribute("data-route");
              console.log(`Navigating to: ${route}`);
              if (route !== "Search") {
                handleItemClick(route || "");
              }
            }
          }}
        >
          {
            menuItems.map((item) => (
              <Box
                key={item.text}
                sx={{
                  borderBottom: item.text === "Search" ? "1px solid var(--neuro-secondary_border)" : "none",
                  padding: "10px",
                  fontSize: "var(--neuro-font-size-extra-small)",
                  color: "var(--neuro-darkgrey_border)",
                  cursor: disabledObj["FileUpload"] && item.text === "FileUpload" || disabledObj["Ranking"] && item.text === "Ranking" ? "not-allowed" : "pointer",
                  pointerEvents: disabledObj["FileUpload"] && item.text === "FileUpload" || disabledObj["Ranking"] && item.text === "Ranking" ? "none" : "auto",
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  alignItems: "center",
                  transition: "backgroundColor 0.2s ease-in-out",
                  opacity: disabledObj["FileUpload"] && item.text === "FileUpload" || disabledObj["Ranking"] && item.text === "Ranking" ? 0.7 : 1,
                  "&:hover": {
                    backgroundColor: "#f0f0f0"
                  }
                }}
                onClick={() => item.text !== "Search" && handleItemClick(item.route)}
              >
                {item.icon} {item.text}
              </Box>
            ))
          }
        </Box>
      </Box>
    </Box>
  );
};

export default FormSidebarcomp;
