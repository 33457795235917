import React, { useCallback, useMemo, useState } from "react";
import {
  Box,
  Typography,
  Avatar,
  Divider,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { AppointmentCardProps } from "../../../types/appointments";
import {
  calculateAgeInYearsOrMonths,
  getAppointmentStatus,
  getFormattedDateTime,
  getStatusColor,
} from "../../../utils/appointments";
import { capitalizeFirstLetter } from "../../../utils/common";
import { differenceInMinutes, format, parseISO, subYears } from "date-fns";
import {
  CANCELLED,
  COMPLETED,
  NOT_HAPPENED,
  ONGOING,
  ONLINE,
  PENDINGTIMESLOT,
  UPCOMING,
} from "../../../utils/constants";
import { Tax } from "../../../types/common";
import VideoCallIcon from "../../svg/VideoCallIcon";
import HospitalIcon from "../../svg/HospitalIcon";
import { isClinicAdminOrAdmin, isDoctorOrAdminOrClinicAdmin, isPatient } from "../../../utils/auth";
import useAuthStore from "../../../store/authStore";
import { payForAppointment } from "../../../services/appointmentsService";
import useAppStore from "../../../store/appStore";
import useAppointmentStore from "../../../store/appointmentsStore";
import Spinner from "../../ui/Spinner";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import Button from "../../ui/Button";
import PaymentIcon from "@mui/icons-material/Payment";
import config from "../../../config";
import api from "../../../services/apiService";
import NewAppointmentCardButtons from "./NewAppointmentCardButtons";
import { useNavigate } from "react-router-dom";
import PatientName from "./PatientName";
import DoctorName from "./DoctorName";

const NewAppointmentCard: React.FC<AppointmentCardProps> = ({
  appointmentCode,
  id,
  availableSlot,
  doctor,
  status,
  patient,
  paymentStatus,
  appointmentMode,
  prescription,
  consultationType,
  reasonForAppointment,
  from = "",
  patientId = null,
}) => {
  // props & state values
  const { userRole, user } = useAuthStore();
  const navigate = useNavigate();

  const {
    showSnackbar,
    appointmentsFilter,
    setAppointmentMeetId,
    appointmentIdForMeet
  } = useAppStore();

  const { fetchAppointments } = useAppointmentStore();

  const isMobile = useMediaQuery("(max-width:37.5rem)");

  const [payOfflineLoading, setPayOfflineLoading] = useState<boolean>(false);

  const formattedDate = useMemo(() => {
    if (!availableSlot?.startTime) return null;
    const DateTimeObject = getFormattedDateTime(availableSlot.startTime);

    if (DateTimeObject && typeof DateTimeObject === "object") {
      const { day, month, year, hours, minutes, amOrPm } = DateTimeObject;
      return {
        date: `${day} ${month} ${year}`,
        time: `${hours}:${minutes} ${amOrPm}`,
      };
    } else return "";
  }, [availableSlot]);

  const appointementPatientName = `${capitalizeFirstLetter(patient?.user?.firstName || "")}`;
  const doctorName = `Dr. ${capitalizeFirstLetter(doctor?.user?.firstName || "")}`;

  const patientAge = `${patient?.age && patient?.age !== ""
    ? `${patient?.age} yrs`
    : `${calculateAgeInYearsOrMonths(patient?.dateOfBirth || "")}` || ""
    } ${patient?.age && patient?.age !== ""
      ? `(${getBirthDateFromAge(Number(patient?.age) || 0)})`
      : `(${formatDateString(patient?.dateOfBirth || "")})` || ""
    }`;

  const patientGender = patient?.gender
    ? patient?.gender?.charAt(0).toUpperCase() +
    patient?.gender.slice(1).toLowerCase()
    : "";

  const charges =
    appointmentMode === ONLINE
      ? doctor?.onlineConsultationFee || null
      : doctor?.inPersonConsultationFee || null;

  const taxes: Tax[] = doctor?.taxes || [];

  const totalAmount = charges
    ? taxes && taxes?.length > 0
      ? calculateTotalAmount()
      : charges
    : null;

  const paymentText = `${paymentStatus && paymentStatus === "COMPLETED" ? "Paid" : "Unpaid"} ${doctor?.defaultClinic?.currency || "INR"} ${totalAmount || ""}`;

  const appointmentStatus = useMemo(
    () =>
      getAppointmentStatus(availableSlot?.startTime, availableSlot?.endTime),
    [availableSlot]
  );

  const payButtonCondition =
    status !== COMPLETED &&
    status !== CANCELLED &&
    appointmentStatus !== NOT_HAPPENED &&
    paymentStatus &&
    paymentStatus === "PENDING";

  const showPayOfflineButton =
    isClinicAdminOrAdmin(userRole) && payButtonCondition;

  const showPayButton =
    (isPatient(userRole) || isClinicAdminOrAdmin(userRole)) &&
    payButtonCondition;

  const isCompletedOrCancelled = useMemo(
    () =>
      [CANCELLED, COMPLETED].includes(status) ||
      appointmentStatus === NOT_HAPPENED,
    [status, appointmentStatus]
  );

  const cardStyles = useMemo(() => {
    const background = isCompletedOrCancelled
      ? "var(--neuro-bg-light-grey-secondary)"
      : "var(--neuro-white-text)";
    const borderLeft = isCompletedOrCancelled
      ? "1px solid var(--neuro-secondary_border)"
      : appointmentStatus === UPCOMING
        ? "4px solid var(--neuro-button-bg-primary)"
        : appointmentStatus === ONGOING
          ? "4px solid var(--neuro-button-bg-success)"
          : "1px solid var(--neuro-secondary_border)";

    return {
      background,
      borderLeft,
      opacity: isCompletedOrCancelled ? 0.9 : 1,
    };
  }, [status, appointmentStatus]);

  // callbacks & functions
  function calculateTotalAmount() {
    return taxes?.reduce((total, tax) => {
      if (tax.type === "PERCENTAGE") {
        return total + (charges * parseFloat(tax.value)) / 100 || null;
      } else if (tax.type === "FIXED") {
        return total + parseFloat(tax.value) || null;
      }
      return total || null;
    }, charges);
  }

  function getBirthDateFromAge(age: number): string {
    if (!age) {
      return "";
    }
    const birthDate = subYears(new Date(), age);
    return format(birthDate, "dd-MM-yyyy"); // Change to 'dd-MM-yyyy'
  }

  function formatDateString(dateString: string): string {
    if (!dateString || dateString === "") {
      return "";
    }

    // const date = parseISO(dateString);
    return format(dateString, "dd-MM-yyyy") || "";
  }

  function getTimeDifferenceInMinutes(start: string, end: string): string {
    if (!start || start === "" || !end || end === "") {
      return "mins";
    }
    const startDate = parseISO(start);
    const endDate = parseISO(end);
    return `${differenceInMinutes(endDate, startDate) || ""} mins`;
  }

  // pay-offline by admin handler
  const handleOfflinePayment = async (id: string) => {
    try {
      setPayOfflineLoading(true);
      // const result = await offlinePayment(id);
      await payForAppointment(id, {
        paymentType: "OFFLINE",
      });

      setPayOfflineLoading(false);
      showSnackbar("Paid offline, successufully", "success");
      if (from === "dashboard") {
        fetchAppointments(
          appointmentsFilter.customStartDate,
          appointmentsFilter.customEndDate,
          appointmentsFilter.status || "",
          patientId || "",
          "DASHBOARD",
          appointmentsFilter?.searchValue || ""
        );
      } else {
        fetchAppointments(
          appointmentsFilter.customStartDate,
          appointmentsFilter.customEndDate,
          appointmentsFilter.status || "",
          patientId || "",
          "",
          appointmentsFilter?.searchValue || ""
        );
      }
    } catch (error) {
      setPayOfflineLoading(false);
      showSnackbar("Could not complete pay offline, please try again", "error");
    }
  };

  // pay handler
  const handlePayment = useCallback(
    async (appointmentId: string, amount: number | null) => {
      try {
        // const params = { paymentType: "PAYLATER" };

        // const { data } = await api.post(
        //   `/api/payments/createOrder`,
        //   { appointmentId, amount },
        //   { params }
        // );

        const { data } = await payForAppointment(appointmentId, {
          paymentType: "PAYNOW",
        });

        const { id, currency } = data;

        const options = {
          key: config.RAZORPAY_KEY_ID,
          amount,
          currency,
          name: config.APP_NAME,
          description: config.RAZORPAY_DESCRIPTION,
          order_id: id,
          handler: async (response: any) => {
            try {
              const result = await api.post(`/api/payments/verifySignature/new`, {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                appointmentId,
              });
              if (result.data.status === "success") {
                showSnackbar("Payment successful", "success");
                // fetchAppointments(
                //   appointmentsFilter.customStartDate,
                //   appointmentsFilter.customEndDate,
                //   appointmentsFilter.status || "",
                //   patientId || ""
                // );

                if (from === "dashboard") {
                  fetchAppointments(
                    appointmentsFilter.customStartDate,
                    appointmentsFilter.customEndDate,
                    appointmentsFilter.status || "",
                    patientId || "",
                    "DASHBOARD",
                    appointmentsFilter?.searchValue || ""
                  );
                } else {
                  fetchAppointments(
                    appointmentsFilter.customStartDate,
                    appointmentsFilter.customEndDate,
                    appointmentsFilter.status || "",
                    patientId || "",
                    "",
                    appointmentsFilter?.searchValue || ""
                  );
                }
              } else {
                showSnackbar(
                  "Payment not completed, please try again",
                  "error"
                );
              }
            } catch {
              showSnackbar("Payment not completed, please try again", "error");
            }
          },
          prefill: {
            // name:
            //   `${patient?.user?.firstName} ${patient?.user?.lastName}` || "",
            name: `${patient?.user?.firstName}` || "",
            email: patient?.user?.email || "",
            contact: patient?.user?.mobile || "",
          },
          theme: { color: "#3399cc" },
          modal: {
            ondismiss: () =>
              showSnackbar("Payment was cancelled by the user", "info"),
          },
        };

        const rzp1 = new (window as any).Razorpay(options);
        rzp1.on("payment.failed", (response: any) =>
          showSnackbar(
            `Payment failed. Reason: ${response.error.description}`,
            "error"
          )
        );
        rzp1.open();
      } catch (error) {
        console.error(error);
      }
    },
    [showSnackbar, fetchAppointments, patientId, patient, user]
  );

  const handleCardClick = useCallback(() => {
    if (patientId) {
      if (from === "meet") {
        navigate(`/meet/${appointmentIdForMeet}/appointments/${appointmentIdForMeet}/details`);
        setAppointmentMeetId(id);
        // setAppointmentConferenceView("Details");
      }
      else {
        if (isDoctorOrAdminOrClinicAdmin(userRole)) {
          navigate(`/patients/${patientId}/appointments/${id}/details`);
        }
      }
    }
    else {
      navigate(`/appointment/${id}`);
    }
  }, [navigate, id, patientId]);

  return (
    <Box
      sx={{
        borderRadius: "0.75rem",
        backgroundColor: cardStyles.background,
        display: "flex",
        gap: "0.75rem",
        padding: "0.625rem 1.188rem 0.625rem 1.188rem",
        marginBottom: "1rem",
        border: "0.063rem solid var(--neuro-secondary_border)",
        borderLeft: cardStyles.borderLeft,
        cursor: "pointer",
      }}
      onClick={handleCardClick}
    >
      <Box
        sx={{
          alignSelf: "start",
          display: "flex",
          marginTop: "0.875rem",
          flexDirection: "column",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        {appointmentMode === ONLINE ? (
          <VideoCallIcon
            height="1.5rem"
            color="var(--neuro-button-bg-success)"
          />
        ) : (
          <HospitalIcon height="1.5rem" />
        )}

        <Box sx={{ width: "max-content" }}>
          <Typography sx={{ marginTop: "0.438rem", lineHeight: "120%" }}>
            {formattedDate ? formattedDate?.time || "" : ""}
          </Typography>
          <Typography
            sx={{
              fontWeight: "var(--neuro-font-weight-small)",
              lineHeight: "120%",
            }}
          >
            IST
          </Typography>
        </Box>
      </Box>
      <Divider
        orientation="vertical"
        sx={{
          height: "auto",
          ml: "0.25rem",
          color: "var(--neuro-secondary_border)",
          backgroundColor: "var(--neuro-secondary_border)",
        }}
      />
      <Box
        sx={{
          zIndex: 10,
          display: "flex",
          flexDirection: "column",
          lineHeight: "120%",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: "1.25rem",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "start",
              gap: "0.438rem",
            }}
          >
            <Avatar
              sx={{
                width: "2.875rem",
                height: "2.875rem",
                bgcolor: "var(--neuro-light-grey_border)",
                mt: "0.188rem",
              }}
              src={patient?.user?.profileImageUrl || undefined}
            />
            <Box
              sx={{
                display: "flex",
                marginTop: "0.563rem",
                flexDirection: "column",
                alignItems: "start",
                flexGrow: 1,
                flexBasis: 0,
                width: "fit-content",
              }}
            >
              <PatientName
                name={appointementPatientName}
                id={patient?.id || ""}
                patient={patient}
              />

              <Typography
                sx={{
                  color: "var(--neuro-bg-darkblue-primary)",
                  fontSize: "var(--neuro-font-size-smallest-x)",
                  lineHeight: "120%",
                  fontFamily: "var(--neuro-font-family-roboto-slab)",
                  mb: "0.375rem",
                }}
              >
                {`${patientAge || ""}, ${patientGender || ""}`}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "var(--neuro-font-family-roboto-slab)",
                  color: "var(--neuro-black-text)",
                  fontSize: "var(--neuro-font-size-smallest-x)",
                  lineHeight: "120%",
                }}
              >
                <Typography
                  sx={{
                    alignSelf: "stretch",
                  }}
                >
                  With{" "}
                  <DoctorName
                    name={doctorName}
                    id={doctor?.id || ""}
                    from={from}
                  />{" "}
                  <span
                    style={{
                      alignSelf: "stretch",
                    }}
                  >
                    {`${doctor?.defaultClinic?.name ? "at" : ""} ${doctor?.defaultClinic?.name || ""} |
                  ${appointmentMode
                        ? appointmentMode === ONLINE
                          ? "Online Consultation"
                          : "Clinic Visit"
                        : ""
                      } (${getTimeDifferenceInMinutes(availableSlot?.startTime || "", availableSlot?.endTime || "mins")})`}
                  </span>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              alignSelf: "start",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Typography
                sx={{
                  color:
                    status === CANCELLED
                      ? "var(--neuro-grey-variant1)" :
                      status === PENDINGTIMESLOT ? "var(--neuro-secondary-light-error)"
                        : status === COMPLETED
                          ? "var(--neuro-grey-variant1)"
                          : getStatusColor(appointmentStatus),
                  fontSize: "var(--neuro-font-size-x-small)",
                  fontFamily: "var(--neuro-font-family-roboto-slab)",
                  lineHeight: "120%",
                  textAlign: "center",
                }}
              >
                {status === CANCELLED
                  ? CANCELLED : status === PENDINGTIMESLOT ? PENDINGTIMESLOT
                    : status === COMPLETED
                      ? COMPLETED
                      : appointmentStatus ? appointmentStatus === NOT_HAPPENED ? "NOT HAPPENED" : appointmentStatus : ""}
              </Typography>
              <Typography
                sx={{
                  color:
                    paymentStatus === COMPLETED
                      ? "var(--neuro-black-text)"
                      : "var(--neuro-primary-error)",
                  textAlign: "right",
                  fontSize: "var(--neuro-font-size-x-small)",
                  lineHeight: "120%",
                }}
              >
                {paymentText}
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                marginTop: "0.438rem",
                gap: "0.5rem",
              }}
            >
              {showPayButton &&
                (isMobile ? (
                  <IconButton
                    disableRipple
                    disabled={!totalAmount}
                    sx={{
                      padding: 0,
                      backgroundColor: "transparent",
                      "& :hover": {
                        padding: 0,
                        backgroundColor: "transparent",
                      },
                    }}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      event.stopPropagation();
                      handlePayment(id, totalAmount);
                    }}
                  >
                    <PaymentIcon
                      sx={{
                        fontSize: "var(--neuro-font-size-tiny-plus)",
                        color: "var(--neuro-black-text)",
                      }}
                    />
                  </IconButton>
                ) : (
                  <Button
                    variant="contained"
                    className="success-gradient-button"
                    startIcon={
                      <PaymentIcon
                        sx={{
                          fontSize: "var(--neuro-font-size-tiny-plus)",
                          color: "var(--neuro-white-text)",
                        }}
                      />
                    }
                    sx={{
                      textTransform: "none",
                      height: "2.25rem",
                    }}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      event.stopPropagation();
                      handlePayment(id, totalAmount);
                    }}
                    disabled={!totalAmount}
                  >
                    Pay
                  </Button>
                ))}

              {showPayOfflineButton &&
                (isMobile ? (
                  <IconButton
                    disableRipple
                    disabled={!totalAmount || payOfflineLoading}
                    sx={{
                      padding: 0,
                      backgroundColor: "transparent",
                      "& :hover": {
                        padding: 0,
                        backgroundColor: "transparent",
                      },
                    }}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      event.stopPropagation();
                      handleOfflinePayment(id);
                    }}
                  >
                    {payOfflineLoading ? (
                      <Spinner />
                    ) : (
                      <CreditScoreIcon
                        sx={{
                          fontSize: "var(--neuro-font-size-tiny-plus)",
                          color: "var(--neuro-black-text)",
                        }}
                      />
                    )}
                  </IconButton>
                ) : (
                  <Button
                    className="primary-button"
                    variant="contained"
                    startIcon={
                      <CreditScoreIcon
                        sx={{
                          fontSize: "var(--neuro-font-size-tiny-plus)",
                          color: "var(--neuro-white-text)",
                        }}
                      />
                    }
                    sx={{
                      textTransform: "none",
                      height: "2.25rem",
                    }}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                      event.stopPropagation();
                      handleOfflinePayment(id);
                    }}
                    disabled={!totalAmount || payOfflineLoading}
                  >
                    {payOfflineLoading ? <Spinner /> : "Pay Offline"}
                  </Button>
                ))}
            </Box>
          </Box>
        </Box>
        <Divider sx={{ mt: "0.75rem" }} />
        <Box
          sx={{
            alignSelf: "center",
            display: "flex",
            marginTop: "0.75rem",
            width: "100%",
            maxWidth: "100%",
            gap: "1.5rem",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <NewAppointmentCardButtons
            key={id}
            appointmentCode={appointmentCode}
            id={id}
            userRole={userRole}
            appointmentMode={appointmentMode}
            status={status}
            availableSlot={availableSlot}
            paymentStatus={paymentStatus}
            doctor={doctor}
            user={user}
            prescription={prescription}
            patient={patient}
            consultationType={consultationType}
            reasonForAppointment={reasonForAppointment}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default NewAppointmentCard;
