const config = {
  APP_NAME: process.env.APP_NAME || "22Neuro Clinic",
  VERSION: process.env.VERSION || "Test",
  RAZORPAY_KEY_ID: process.env.RAZORPAY_KEY_ID || "rzp_test_bbZsNKZTldBRBU",
  RAZORPAY_DESCRIPTION:
    process.env.RAZORPAY_DESCRIPTION || "Appointment Booking",
  GOOGLE_MAPS_API_KEY:
    process.env.GOOGLE_MAPS_API_KEY ||
    "AIzaSyC1RyEK7PkeuJkR-Oskd54kQfCMpoTFsms",
  MEETING_ENABLE_TIME: 60,
  MEETING_ONGOING_TIME_AFTER_ENDTIME: 60,
  SEARCH_FILTER_LETTER: 3,
  RESCHEDULE_TIME_LIMIT: 10,
  SUPPORT_NUMBER: "84119-11234",
  DEFAULT_APPOINTMENTS_VIEW: "table",
  DEFAULT_DOCTORS_VIEW: "table",
  DEFAULT_DOCUMENTS_VIEW: "table",
  DEFAULT_HOSPITALS_VIEW: "table",
  DEFAULT_PATIENTS_VIEW: "table",
  DEFAULT_PAYMENTS_VIEW: "table",
  DEFAULT_REFERRALS_VIEW: "table",
  HIGHLIGHT_DURATION: 3000,
  APP_BASE_URL: "https://22neuro.continuous.engineering",
};

export default config;
