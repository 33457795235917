import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";


interface Choice {
  value: string | boolean | number;
  text: string;
  score?: number;
}

interface RadioGroupQuestion {
  type: string;
  name: string;
  title: string;
  choices: Choice[];
  isRequired: boolean;
  showScore: boolean;
}

interface DynamicRadioGroupProps {
  question: RadioGroupQuestion;
  index: number;
  handleDuplicateQuestion: (index: number) => void;
  handleRemoveQuestion: (index: number) => void;
  updateQuestion: (index: number, updatedQuestion: RadioGroupQuestion) => void;
  isonclick?: boolean;
  showHighlighted: boolean;
}

const DynamicRadioGroup: React.FC<DynamicRadioGroupProps> = ({
  question,
  index,
  handleDuplicateQuestion,
  handleRemoveQuestion,
  updateQuestion,
  showHighlighted = false,
  isonclick = false
}) => {
  const [localQuestion, setLocalQuestion] =
    useState<RadioGroupQuestion>(question);

  useEffect(() => {
    setLocalQuestion(question);
  }, [question]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      updateQuestion(index, { ...localQuestion });
    }, 200);
    return () => clearTimeout(timeout);
  }, [localQuestion, index, updateQuestion]);

  const handleChoiceChange = (
    choiceIndex: number,
    field: keyof Choice,
    value: string | boolean | number | undefined
  ) => {
    const updatedChoices = localQuestion.choices.map((choice, idx) => {
      if (idx === choiceIndex) {
        const updatedChoice = { ...choice, [field]: value };

        if (field === "score" && typeof value === "number") {
          updatedChoice.value = value; // Sync value with score
        }

        return updatedChoice;
      }
      return choice;
    });

    setLocalQuestion((prev) => ({ ...prev, choices: updatedChoices }));
  };

  const addChoice = () => {
    setLocalQuestion((prev) => ({
      ...prev,
      choices: [
        ...prev.choices,
        { value: "0", text: "New Choice", score: 0 },
      ],
    }));
  };

  const removeChoice = (choiceIndex: number) => {
    const updatedChoices = localQuestion.choices.filter(
      (_, idx) => idx !== choiceIndex
    );
    setLocalQuestion((prev) => ({ ...prev, choices: updatedChoices }));
  };

  return (
    <Box sx={{
      margin: 0,
      padding: "1rem",
      borderRadius: "0.5rem",
      marginBottom: "1rem",
      cursor: "pointer",
      transition: "border-color 0.3s ease",
      background: "var(--neuro-white-text)",
      height: "auto",
      border: isonclick || showHighlighted ? "1px solid var(--neuro-light_red_border) !important" : "1px solid transparent !important",
      "&:hover": {
        border: "1px solid var(--neuro-light_red_border_light) !important"
      }
    }}>
      <div className="question-header">
        {localQuestion.name}{" "}
        <input
          type="text"
          value={localQuestion.title}
          onChange={(e) =>
            setLocalQuestion((prev) => ({
              ...prev,
              title: e.target.value,
            }))
          }
          className="question-name-input survey-js-gobal-input"
          placeholder="Enter question title"
        />
        {localQuestion.isRequired && <span className="required">*</span>}
      </div>

      <div className="radio-group-choices">
        {localQuestion.choices.map((choice, idx) => (
          <Box key={idx} sx={{
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
            mb: "1rem"
          }}>
            <button className="remove-button" onClick={() => removeChoice(idx)}>
              -
            </button>
            <input
              type="radio"
              name={`question-${index}`}
              checked={choice.value === true}
              onChange={() =>
                handleChoiceChange(idx, "value", true) // Ensures only one is selected
              }
              className="radio"
            />
            <input
              type="text"
              value={choice.text}
              onChange={(e) =>
                handleChoiceChange(idx, "text", e.target.value)
              }
              placeholder="Choice text"
              className="edit-label-input survey-js-gobal-input"
            />
            {localQuestion.showScore && (
              <input
                type="number"
                value={choice.score || ""}
                onChange={(e) =>
                  handleChoiceChange(
                    idx,
                    "score",
                    e.target.value ? parseFloat(e.target.value) : undefined
                  )
                }
                placeholder="Score"
                className="edit-score-input survey-js-gobal-input"
              />
            )}
          </Box>
        ))}
        <button className="add-button" onClick={addChoice}>
          +
        </button>
      </div>

      <div className="question-controls">
        <button
          className="duplicate-button button-spacing blue-bg"
          onClick={() => handleDuplicateQuestion(index)}
        >
          Duplicate
        </button>

        <button
          className="delete-button button-spacing red-bg"
          onClick={() => handleRemoveQuestion(index)}
        >
          Delete
        </button>

        <button
          className={`required-button ${localQuestion.isRequired ? "active1" : ""
            }`}
          onClick={() =>
            setLocalQuestion((prev) => ({
              ...prev,
              isRequired: !prev.isRequired,
            }))
          }
          aria-pressed={localQuestion.isRequired}
        >
          {localQuestion.isRequired ? "Required" : "Not Required"}
        </button>
      </div>
    </Box>
  );
};

export default DynamicRadioGroup;
