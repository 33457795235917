import { Box, Typography } from "@mui/material";
import Button from "../../ui/Button";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../../store/authStore";
import Spinner from "../../ui/Spinner";
import api from "../../../services/apiService";
import { extractAppointmentId } from "../../../utils/appointments";
import config from "../../../config";
import {
  failAppointmentBooking,
  fetchPayLater,
  getTimeSlotStatus,
  rescheduleAppointmentApi,
  // updateTimeSlotStatus,
} from "../../../services/appointmentsService";
import useAppStore from "../../../store/appStore";
import useAppointmentStore from "../../../store/appointmentsStore";
import {
  ONLINE,
  //  AVAILABLE,
  BOOKED, ON_LEAVE, CANCELLED,
  PENDINGTIMESLOT
} from "../../../utils/constants";
import { PatientAppointmentsProps, Tax } from "../../../types/common";
import { isClinicAdminOrAdmin, isDoctorOrAdminOrClinicAdmin } from "../../../utils/auth";

const PaymentFooter: React.FC<PatientAppointmentsProps> = ({
  patientId,
  patientName,
  patientEmail,
  patientContact,
  from = "",
}) => {
  // props & state values
  const [payLaterLoading, setPayLaterLoading] = useState<boolean>(false);
  const [rescheduleLoading, setRescheduleLoading] = useState<boolean>(false);

  const { showSnackbar, setAppointmentMeetId, setAppointmentConferenceView } =
    useAppStore();

  const {
    setAppointmentId,
    selectedDoctor,
    selectedMeetingType,
    availableSlotId,
    appointmentDate,
    cleanUpAppointmentState,
    selectedTime,
    selectedConsultationType,
    reasonForAppointment,
    setPaymentCompleted,
    paymentCompleted,
    appointmentId,
    isRescheduleAppointment
  } = useAppointmentStore();

  console.log("availableSlotId in payment", availableSlotId)
  console.log("selectedConsultationType in payment", selectedConsultationType)
  console.log("reasonForAppointment in payment", reasonForAppointment)

  const navigate = useNavigate();

  const { user, userRole } = useAuthStore();

  const charges =
    selectedMeetingType === ONLINE
      ? selectedDoctor?.onlineConsultationFee || null
      : selectedDoctor?.inPersonConsultationFee || null;

  const taxes: Tax[] = selectedDoctor?.taxes || [];

  const calculateTotalAmount = () => {
    return taxes?.reduce((total, tax) => {
      if (tax.type === "PERCENTAGE") {
        return total + (charges * parseFloat(tax.value)) / 100 || null;
      } else if (tax.type === "FIXED") {
        return total + parseFloat(tax.value) || null;
      }
      return total || null;
    }, charges);
  };

  const totalAmount = charges
    ? taxes && taxes?.length > 0
      ? calculateTotalAmount()
      : charges
    : null;

  // callbacks & functions
  const fetchfailAppointment = async (id: string) => {
    try {
      await failAppointmentBooking(id);
    } catch (error) {
      console.error("Error fetching referrals:", error);
    }
  };

  const fetchTimeSlotStatus = async (slotId: string) => {
    try {
      const response = await getTimeSlotStatus(slotId);
      return response?.data?.SlotStatus || null;
    }
    catch (error) {
      return null;
    }
  }

  // const callUpdateTimeSlot = async (slotId: string, status: string) => {
  //   try {
  //     await updateTimeSlotStatus({
  //       slotId: slotId,
  //       status: status
  //     })
  //   }
  //   catch (error) {
  //     console.error("Error in updating time slot", error);
  //   }
  // }

  const processPayLater = async (paymentType: string, data: any) => {
    // await callUpdateTimeSlot(availableSlotId || "", IN_PROGRESS);

    const params = {
      paymentType,
    };

    try {
      const response = await fetchPayLater(params, data);
      if (response?.data?.id) {
        // await callUpdateTimeSlot(availableSlotId || "", BOOKED);
        if (paymentType === "OFFLINE") {
          setPaymentCompleted(true);
        }
        handlePaymentSuccess(response?.data?.id);
      }
      setPayLaterLoading(false);
    } catch (error) {
      // await callUpdateTimeSlot(availableSlotId || "", AVAILABLE);
      showSnackbar("Could not process pay later, please try again", "error");
      setPayLaterLoading(false);
    }
  };


  const AppointmentDetailsToast = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "var(--neuro-font-size-tiny)"
          }}
        >
          Appointment created successfully
        </Typography>
      </Box>
    )
  }

  const handlePaymentSuccess = (id: string) => {
    showSnackbar(<AppointmentDetailsToast />, "success");

    if (from === "meet") {
      setAppointmentMeetId(id);
      setAppointmentConferenceView("Details");
    } else if (from === "appointment-cards") {
      navigate(`/appointment/${id}`);
    } else {
      if (patientId) {
        if (isDoctorOrAdminOrClinicAdmin(userRole)) {
          navigate(`/patients/${patientId}/appointments/${id}/details`);
        }
      } else {
        navigate(`/appointment/${id}`);
      }
    }

    cleanUpAppointmentState();
  };



  const handlePayLater = async (query: string) => {
    // checking time slot status
    const slotStatus = await fetchTimeSlotStatus(availableSlotId || "");

    console.log("slotStatus in handle pay later", slotStatus);

    if (slotStatus && slotStatus === ON_LEAVE) {
      showSnackbar("Doctor is on leave on this date, please try some other date", "error");
      return;
    }

    else if (slotStatus && slotStatus === BOOKED) {
      showSnackbar("This slot is booked, please try some other slot", "error");
      return;
    }

    else if (slotStatus && slotStatus === CANCELLED) {
      showSnackbar("This slot is cancelled, please try some other slot", "error");
      return;
    }

    else if (!slotStatus) {
      showSnackbar("Something went wrong, please try again", "error");
      return;
    }

    else {
      if (isRescheduleAppointment) {
        setRescheduleLoading(true);

        const data = {
          appointmentDate,
          availableSlotId,
          consultationType: selectedConsultationType,
          reasonForAppointment: reasonForAppointment,
          paymentType: query
        }
        try {
          await rescheduleAppointmentApi(appointmentId || "", data);
          setRescheduleLoading(false);
          handlePaymentSuccess(appointmentId || "");
          showSnackbar("Appointment rescheduled", "success");
        } catch (error: any) {
          console.log("error in reschedule", error);
          setRescheduleLoading(false);
          showSnackbar(`${error?.response?.data?.message || "Could not resechudle, please try again"}`, "error");
        }
      }

      else {
        let data;

        if (patientId) {
          data = {
            doctorId: selectedDoctor?.id as string,
            appointmentDate: appointmentDate,
            availableSlotId: availableSlotId,
            appointmentMode: selectedMeetingType,
            patientId: patientId,
            consultationType: selectedConsultationType,
            reasonForAppointment: reasonForAppointment,
            amount: totalAmount
          };
        } else {
          data = {
            doctorId: selectedDoctor?.id as string,
            appointmentDate: appointmentDate,
            availableSlotId: availableSlotId,
            appointmentMode: selectedMeetingType,
            consultationType: selectedConsultationType,
            reasonForAppointment: reasonForAppointment,
            amount: totalAmount
          };
        }

        processPayLater(query, data);
      }
    }
  };

  const handlePayment = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    // checking time slot status
    const slotStatus = await fetchTimeSlotStatus(availableSlotId || "");

    console.log("slotStatus in handle pay now", slotStatus);

    if (slotStatus && slotStatus === ON_LEAVE) {
      showSnackbar("Doctor is on leave on this date, please try some other date", "error");
      return;
    }

    else if (slotStatus && slotStatus === BOOKED) {
      showSnackbar("This slot is booked, please try some other slot", "error");
      return;
    }

    else if (slotStatus && slotStatus === CANCELLED) {
      showSnackbar("This slot is cancelled, please try some other slot", "error");
      return;
    }

    else if (!slotStatus) {
      showSnackbar("Something went wrong, please try again", "error");
      return;
    }

    else {
      // await callUpdateTimeSlot(availableSlotId || "", IN_PROGRESS);

      if (isRescheduleAppointment) {
        setRescheduleLoading(true);

        const data = {
          appointmentDate,
          availableSlotId,
          consultationType: selectedConsultationType,
          reasonForAppointment: reasonForAppointment,
          paymentType: "PAYNOW",
        }

        try {
          const order = await rescheduleAppointmentApi(appointmentId || "", data);
          const { id, currency } = order.data;

          try {
            // Configure Razorpay checkout options
            const options = {
              key: config.RAZORPAY_KEY_ID!,
              totalAmount,
              currency: currency,
              name: config.APP_NAME,
              description: config.RAZORPAY_DESCRIPTION,
              order_id: id,
              handler: async (response: any) => {
                const data = {
                  razorpay_order_id: response.razorpay_order_id,
                  razorpay_payment_id: response.razorpay_payment_id,
                  razorpay_signature: response.razorpay_signature,
                  appointmentId: appointmentId,
                };

                // Verify payment signature and update status
                try {
                  const result = await api.post(
                    `/api/payments/verifySignature/new`,
                    data
                  );
                  if (result.data.status === "success") {
                    if (result.data.appointmentStatus === PENDINGTIMESLOT) {
                      showSnackbar("Payment successfull but time slot is not available, please select another time slot", "info");
                      setPaymentCompleted(true);
                      setRescheduleLoading(false);
                      // handlePaymentSuccess(appointmentId || "");
                      // showSnackbar("Appointment rescheduled", "success");
                    }
                    else {
                      showSnackbar("Payment successfull", "success");
                      setPaymentCompleted(true);
                      setRescheduleLoading(false);
                      handlePaymentSuccess(appointmentId || "");
                      showSnackbar("Appointment rescheduled", "success");
                    }
                    // await callUpdateTimeSlot(availableSlotId || "", BOOKED);

                  } else {
                    // await callUpdateTimeSlot(availableSlotId || "", AVAILABLE);
                    showSnackbar("Payment not completed, please try again", "error");
                  }
                } catch (error) {
                  // await callUpdateTimeSlot(availableSlotId || "", AVAILABLE);
                  showSnackbar("Payment not completed, please try again", "error");
                }
              },
              prefill: {
                // name: patientName
                //   ? patientName
                //   : `${user?.firstName} ${user?.lastName}`,
                name: patientName ? patientName : `${user?.firstName}`,
                email: patientEmail ? patientEmail : user?.email,
                contact: patientContact ? patientContact : user?.mobile,
              },
              theme: {
                color: "#3399cc",
              },
              modal: {
                ondismiss: () => {
                  // cancel appointment
                  fetchfailAppointment(appointmentId || "");
                  showSnackbar("Payment was cancelled by the user", "info");
                },
              },
            };

            const rzp1 = new (window as any).Razorpay(options);
            rzp1.on("payment.failed", (response: any) => {
              alert("Payment failed. Reason: " + response.error.description);
            });
            rzp1.open();
          } catch (error) {
            console.error(error);
          }
        } catch (error: any) {
          console.log("error in reschedule", error);
          setRescheduleLoading(false);
          showSnackbar(`${error?.response?.data?.message || "Could not resechudle, please try again"}`, "error");
        }
      }
      else {

        let data;

        if (patientId) {
          data = {
            doctorId: selectedDoctor?.id as string,
            appointmentDate: appointmentDate,
            availableSlotId: availableSlotId,
            appointmentMode: selectedMeetingType,
            amount: totalAmount,
            patientId: patientId,
            consultationType: selectedConsultationType,
            reasonForAppointment: reasonForAppointment,
          };
        } else {
          data = {
            doctorId: selectedDoctor?.id as string,
            appointmentDate: appointmentDate,
            availableSlotId: availableSlotId,
            appointmentMode: selectedMeetingType,
            amount: totalAmount,
            consultationType: selectedConsultationType,
            reasonForAppointment: reasonForAppointment,
          };
        }

        try {
          const params = {
            paymentType: "PAYNOW",
          };
          // const order = await api.post(`/api/payments/createOrder`, data, {
          //   params,
          // });

          const order = await fetchPayLater(params, data);

          console.log("order date in pay now", order);

          const idString = extractAppointmentId(order?.data?.receipt);

          setAppointmentId(idString);
          const { id, currency } = order.data;

          // Configure Razorpay checkout options
          const options = {
            key: config.RAZORPAY_KEY_ID!,
            totalAmount,
            currency: currency,
            name: config.APP_NAME,
            description: config.RAZORPAY_DESCRIPTION,
            order_id: id,
            handler: async (response: any) => {
              const data = {
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
                appointmentId: idString?.trim(),
              };

              // Verify payment signature and update status
              try {
                const result = await api.post(
                  `/api/payments/verifySignature/new`,
                  data
                );
                if (result.data.status === "success") {
                  if (result.data.appointmentStatus === PENDINGTIMESLOT) {
                    showSnackbar("Payment successfull but time slot is not available, please select another time slot", "info");
                    setPaymentCompleted(true);
                  }
                  else {
                    showSnackbar("Payment successfull", "success");
                    setPaymentCompleted(true);
                    handlePaymentSuccess(idString);
                  }
                  // await callUpdateTimeSlot(availableSlotId || "", BOOKED);

                } else {
                  // await callUpdateTimeSlot(availableSlotId || "", AVAILABLE);
                  showSnackbar("Payment not completed, please try again", "error");
                }
              } catch (error) {
                // await callUpdateTimeSlot(availableSlotId || "", AVAILABLE);
                showSnackbar("Payment not completed, please try again", "error");
              }
            },
            prefill: {
              // name: patientName
              //   ? patientName
              //   : `${user?.firstName} ${user?.lastName}`,
              name: patientName ? patientName : `${user?.firstName}`,
              email: patientEmail ? patientEmail : user?.email,
              contact: patientContact ? patientContact : user?.mobile,
            },
            theme: {
              color: "#3399cc",
            },
            modal: {
              ondismiss: () => {
                // cancel appointment
                fetchfailAppointment(idString);
                showSnackbar("Payment was cancelled by the user", "info");
              },
            },
          };

          const rzp1 = new (window as any).Razorpay(options);
          rzp1.on("payment.failed", (response: any) => {
            alert("Payment failed. Reason: " + response.error.description);
          });
          rzp1.open();
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const handleCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    cleanUpAppointmentState();
  };

  const handleRescheduleBooking = async () => {
    setRescheduleLoading(true);
    const data = {
      appointmentDate,
      availableSlotId,
      consultationType: selectedConsultationType,
      reasonForAppointment: reasonForAppointment,
    }
    try {
      await rescheduleAppointmentApi(appointmentId || "", data);
      setRescheduleLoading(false);
      handlePaymentSuccess(appointmentId || "");
      showSnackbar("Appointment rescheduled", "success");
    } catch (error: any) {
      console.log("error in reschedule", error);
      setRescheduleLoading(false);
      showSnackbar(`${error?.response?.data?.message || "Could not resechudle, please try again"}`, "error");
    }
  }

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "var(--neuro-bg-light-grey-secondary)",
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "flex-end",
        paddingRight: "1.188rem",
      }}
    >
      <Button
        variant="text"
        className="secondary-text-button"
        sx={{ marginRight: "1.25rem" }}
        onClick={handleCancel}
      >
        Cancel
      </Button>

      {paymentCompleted &&
        <>
          <Button
            sx={{ textTransform: "none", height: "2.25rem" }}
            className="primary-button"
            variant="contained"

            disabled={rescheduleLoading || !selectedTime ? true : false}
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              handleRescheduleBooking();
            }}
          >
            {rescheduleLoading ? <Spinner /> : "Update"}
          </Button>
        </>
      }

      {!paymentCompleted &&
        <>
          <Button
            variant="contained"
            className="success-gradient-button"
            sx={{
              textTransform: "none",
              marginRight: "1.25rem",
              height: "2.25rem"
            }}
            onClick={handlePayment}
            disabled={totalAmount === null ? true : !selectedTime ? true : false}
          >
            Pay Rs. {totalAmount || ""}
          </Button>

          <Button
            sx={{ textTransform: "none", marginRight: "1.25rem", height: "2.25rem" }}
            className="warning-button"
            variant="contained"
            disabled={
              totalAmount === null
                ? true
                : !selectedTime
                  ? true
                  : payLaterLoading
                    ? true
                    : false
            }
            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
              event.stopPropagation();
              handlePayLater("PAYLATER");
            }}
          >
            {payLaterLoading ? <Spinner /> : "Pay Later"}
          </Button>

          {patientId && isClinicAdminOrAdmin(userRole) && (
            <Button
              sx={{ textTransform: "none", marginRight: "1.25rem", height: "2.25rem" }}
              className="primary-button"
              variant="contained"
              disabled={totalAmount === null ? true : !selectedTime ? true : false}
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                handlePayLater("OFFLINE");
              }}
            >
              Pay Offline
            </Button>
          )}
        </>
      }
    </Box>
  );
};

export default PaymentFooter;
