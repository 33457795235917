import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import AddNewFormCompFormid from './AddNewFormCompFormid';
import { submitFormResponseApisuryvegetcall, submitFormResponseApisuryveputcall } from '../../../services/formService';
import FormDesigner from './FormDesigner';
import useFormStore from '../../../store/formStore';
import NewSurveyComponent from '../SurveyComponents/NewSurveyComponent';

const NewFormpagesEdit: React.FC<{ navlinks: string; submitTrigger: boolean; submitTriggerQuestion: boolean; setSubmitTrigger: any; setSubmitTriggerQuestion: any }> = ({ navlinks, submitTrigger, submitTriggerQuestion, setSubmitTrigger, setSubmitTriggerQuestion }) => {
    // props & state values
    const { selectedInput, setSelectedQuestionType } = useFormStore();
    const [selectedTab, setSelectedTab] = useState<string>(navlinks);

    const [isonclick, setIsOnClick] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);

    const [apiresponse, setApiResponse] = useState<any>({ name: '', id: '', questions: { pages: [] } });

    const [questionData, setQuestionData] = useState<any[]>([]);

    const [formdesignerLoading, setFormDesignerLoading] = useState(true);

    const userId = localStorage.getItem("formid");

    const [allData, setAllData] = useState<any>({
        pages: [
            {
                name: "page1",
                title: "",
                description: "",
                elements: [
                    [],
                ],
            },
        ],
    });

    const isScoreBased = localStorage.getItem("isScoreBased") === "true";
    const isAutoSubmit = localStorage.getItem("autoSubmit") === "true";
    const formName = localStorage.getItem("formname");

    console.log("isAutoSubmit in form", isAutoSubmit);

    const [formJsonData, setFormJsonData] = useState<any>({
        isAutoSubmit: isAutoSubmit,
        isScoreBased: isScoreBased,
        formName: formName,
        pages: [...allData.pages],
    });

    console.log("formJsonData", formJsonData);

    console.log("questionData, allData", questionData, allData);


    const generateQuestionName = (index: number) => `${index + 1}`;

    const { id } = useParams(); // Extract the id from the route parameters

    const UserId = localStorage.getItem("formid");

    console.log("isScoreBased in FormpagesEdit", isScoreBased);

    // callbacks & functions
    useEffect(() => {
        setSelectedTab(navlinks);
    }, [navlinks]); // Runs whenever `navlinks` changes

    useEffect(() => {
        // if (questionData && questionData.length > 0) {
        setAllData((prev: any) => {
            console.log("prev in setAllData UE", prev);
            const updatedPages = [...prev.pages];
            if (!updatedPages[currentPage - 1]) {
                // Ensure the array has an object at the required index
                updatedPages[currentPage - 1] = { elements: [], description: "", title: "" };
            }
            console.log("updatedPages in ue after change in question data", updatedPages);
            updatedPages[currentPage - 1].elements = questionData; // Update the current page's elements
            return { ...prev, pages: updatedPages };
        });
        // }
    }, [questionData, currentPage]);

    useEffect(() => {
        if (questionData.length > 0) {
            // Save updated questionData to localStorage whenever it changes
            localStorage.setItem("questionData", JSON.stringify(questionData));
        }
    }, [questionData]);

    useEffect(() => {
        setAllData((prev: any) => {
            console.log('prev in setAllData UE', prev);

            // Update all pages
            const updatedPages = prev?.pages?.map((page: any) => ({
                ...page,
                elements: page?.elements?.map((element: any) => ({
                    ...element,
                    showScore: isScoreBased, // Update showScore based on isScoreBased
                })),
            }));

            // Return the updated state
            return { ...prev, pages: updatedPages };
        });

        setQuestionData(prevQuestions =>
            prevQuestions.map(question => ({
                ...question,
                showScore: isScoreBased,
            }))
        );

        // setFormJsonData((prev: any) => ({ ...prev, isScoreBased }));
    }, [isScoreBased])

    useEffect(() => {
        setFormJsonData({
            isAutoSubmit: isAutoSubmit,
            isScoreBased: isScoreBased,
            formName: formName,
            pages: [...allData.pages],
        });
    }, [allData, isScoreBased, isAutoSubmit, formName]);

    const handlePageChange = (pageIndex: number) => {
        // Load the new page's data into questionData
        setQuestionData(allData.pages[pageIndex - 1]?.elements || []);
        setCurrentPage(pageIndex);
    };

    const addPage = () => {
        // Define the new page with empty elements initially
        const newPage = {
            name: `page${allData.pages.length + 1}`, // For example, "page2"
            description: "",
            title: "",
            elements: [], // Initialize with empty elements
        };

        // Update the pages in your state with the new empty page
        setAllData((prev: any) => {
            const updatedPages = [...prev.pages, newPage]; // Add the new page
            return { ...prev, pages: updatedPages };
        });

        // After adding the page, automatically navigate to the new page
        const newPageIndex = allData.pages.length + 1;
        handlePageChange(newPageIndex);
    };

    const duplicatePage = (pageIndex: number) => {
        setAllData((prev: any) => {
            const updatedPages = [...prev.pages];
            const pageToDuplicate = updatedPages[pageIndex];

            if (pageToDuplicate) {
                const duplicatedPage = {
                    ...pageToDuplicate,
                    name: `page${updatedPages.length + 1}`, // Assign a new name for the duplicated page
                    elements: [...pageToDuplicate.elements],
                    description: "",
                    title: ""
                    // Deep copy the elements array to avoid mutation
                };

                return { ...prev, pages: [...updatedPages, duplicatedPage] };
            }

            return prev; // If no page to duplicate, return the current state
        });

        // Optionally navigate to the duplicated page
        handlePageChange(allData.pages.length + 1);
    };

    const deletePage = (page: string, pageNumber: number) => {
        console.log("allData before delete page", allData);
        if (pageNumber === 1) {
            setQuestionData(allData.pages[pageNumber]?.elements || []);
        }
        else {
            setQuestionData(allData.pages[pageNumber - 2]?.elements || []);
        }

        setAllData(() => {
            console.log("page to delete", page);
            const updatedPages = [...allData.pages];
            console.log("updatedPages in delete page before splice", updatedPages);
            const pagesArray = [...updatedPages];

            // Remove the page at the specified index
            // updatedPages.splice(pageIndex, 1);
            const pagesAfterDelete = pagesArray.filter((updatedPage) => updatedPage.name !== page)

            console.log("pagesAfterDelete in delete page after filter", pagesAfterDelete);

            // Reassign names to maintain sequential naming
            const renamedPages = pagesAfterDelete.map((page, index) => ({
                ...page,
                name: `page${index + 1}`,
            }));

            console.log("renamedPages, currentPage in delete", renamedPages, currentPage);

            // setQuestionData(renamedPages[currentPage-1])
            return {
                pages: renamedPages.length > 0 ? renamedPages : [
                    {
                        name: "page1",
                        description: "",
                        title: "",
                        elements: [
                            [],
                        ],
                    },
                ]
            };
        });

        setCurrentPage((prevCurrentPage) => {
            const newCurrentPage = prevCurrentPage > 1 ? prevCurrentPage - 1 : 1;
            return newCurrentPage;
        });
    };

    // Update function for the question
    const updateQuestion = (index: number, updatedQuestion: any) => {
        const updatedQuestionData = [...questionData];
        console.log("updatedQuestionData", updatedQuestionData);
        updatedQuestionData[index] = updatedQuestion;
        setQuestionData(updatedQuestionData);
    };

    // Toggle the 'isRequired' field for the question
    const toggleRequired = (index: number) => {
        const updatedQuestionData = [...questionData];
        updatedQuestionData[index].isRequired = !updatedQuestionData[index].isRequired;
        setQuestionData(updatedQuestionData);
    };

    const updateQuestionName = (index: number, updatedName: string) => {
        setQuestionData((prev) => {
            const updatedQuestions = [...prev];
            updatedQuestions[index] = { ...updatedQuestions[index], name: updatedName };
            return updatedQuestions;
        });
    };

    useEffect(() => {
        if (selectedInput) {
            switch (selectedInput) {
                case "Checkboxes":
                    addCheckboxQuestion();
                    break;
                case "RatingScale":
                    addRatingScale();
                    break;
                case "MultiDropdown":
                    DynamicSelectSelect();
                    break;
                case "FileUpload":
                    Fileuploadmodel();
                    break;
                case "ImagePicker":
                    imagepicker();
                    break;
                case "RadioButtonGroup":
                    Radiogroup11();
                    break;
                case "SelectDropdown":
                    DropdownSelectSelect();
                    break;
                case "Ranking":
                    addSwitchranking();
                    break;
                case "Yes/No(Boolean)":
                    addSwitchboolean();
                    break;
                case "TextFiled":
                    addTestfiled();
                    break;
                case "Radiogroup":
                    addRadioQuestion();
                    break;
                default:
                    console.warn("Unhandled radio value:", selectedInput);
            }
            // setSelectedInput(null);
        }
    }, [selectedInput]);

    const addCheckboxQuestion = () => {
        const newCheckboxQuestion = {
            type: "checkbox",
            name: `${generateQuestionName(questionData.length)}`, // Dynamically generate the name
            title: "What features did you like the most?", // Add the question title
            isRequired: true, // Make the question required
            showScore: isScoreBased,
            choices: [
                { value: "10", text: "Feature 1", "score": 0 },
                { value: "feature2", text: "Feature 2", "score": 0 },
                { value: "feature3", text: "Feature 3", "score": 0 },
                { value: "feature4", text: "Feature 4", "score": 0 },
            ],
            correctAnswer: [], // Set the correct answers
        };

        setQuestionData([...questionData, newCheckboxQuestion]);
    };

    // const handleOnClickPage = () => {
    //     setIsOnClick(!isonclick);
    // }

    const openisonclick = () => {
        setIsOnClick(true);
    }

    const closeisonclick = () => {
        setIsOnClick(false);
    }

    const DynamicSelectSelect = () => {
        const newCheckboxQuestion = {
            type: "tagbox",
            name: `${generateQuestionName(questionData.length)}`, // Dynamically generate the name
            title: "What features did you like the most?", // Add the question title
            isRequired: true, // Make the question required
            showScore: isScoreBased,

            choices: [
                { value: "feature1", text: "Feature 1", "score": 5 },
                { value: "feature2", text: "Feature 2", "score": 4 },
                { value: "feature3", text: "Feature 3", "score": 3 },
                { value: "feature4", text: "Feature 4", "score": 2 },
            ],
            correctAnswer: [], // Set the correct answers
        };

        setQuestionData([...questionData, newCheckboxQuestion]);
    };

    const addRatingScale = () => {
        const newQuestion = {
            type: "rating",
            showScore: isScoreBased,
            name: `${generateQuestionName(questionData.length)}`, // Generate question name
            title: "What features did you like the most?",
            // rateValues: [1, 2, 3, 4, 5],
            rateValues: [
                { value: "1", text: "1", "score": 1 },
                { value: "2", text: "2", "score": 2 },
                { value: "3", text: "3", "score": 3 },
                { value: "4", text: "4", "score": 4 },
            ],
            required: false,
            autoGenerate: false,
            correctAnswer: [],
        };
        setQuestionData([...questionData, newQuestion]);
    };

    // Function to add a radio question
    const addRadioQuestion = () => {
        const newQuestion = {
            "type": "radiogroup",
            "name": `${generateQuestionName(questionData.length)}`,
            "title": "What features did you like the most?",
            "isRequired": true,
            "choices": [
                {
                    "value": "feature1",
                    "text": "Feature 1"
                },
                {
                    "value": "feature2",
                    "text": "Feature 2"
                },
                {
                    "value": "feature3",
                    "text": "Feature 3"
                },
                {
                    "value": "feature4",
                    "text": "Feature 4"
                }
            ],
            "correctAnswer": []
        }
        setQuestionData((prevData) => [...prevData, newQuestion]);

    };

    // Function to remove a question
    const handleRemoveQuestion = (index: number) => {
        const updatedData = questionData.filter((_, i) => i !== index);
        console.log("updatedData in remove", updatedData);
        const renamedData = updatedData.map((question, idx) => ({
            ...question,
            name: `${idx + 1}`, // Regenerate names based on index
        }));
        console.log("renamedData in remove", renamedData);
        setQuestionData(renamedData);
        // setQuestionData(
        //     updatedData.map((question) => ({
        //         ...question,
        //         name: `page${currentPage}-${generateQuestionName(questionData.length)}`,
        //     }))
        // );
    };

    const DropdownSelectSelect = () => {
        const newCheckboxQuestion = {
            type: "dropdown",
            name: `${generateQuestionName(questionData.length)}`,
            title: "What features did you like the most?",
            showScore: isScoreBased,

            choices: [
                { value: "feature1", text: "Feature 1", "score": 5 },
                { value: "feature2", text: "Feature 2", "score": 4 },
                { value: "feature3", text: "Feature 3", "score": 3 },
                { value: "feature4", text: "Feature 4", "score": 2 },
            ],
            isRequired: true,
        };
        setQuestionData((prevData) => [...prevData, newCheckboxQuestion]);

    }

    const addSwitchranking = () => {
        const newRankingQuestion = {
            type: "ranking",
            name: `${generateQuestionName(questionData.length)}`,
            title: "Rank the following features based on importance:",
            isRequired: false,
            showScore: isScoreBased,
            // choices: [
            //     "Feature A",
            //     "Feature B",
            //     "Feature C",
            //     "Feature D"
            // ],

            choices: [
                { value: "feature1", text: "Feature 1", "score": 0 },
                { value: "feature2", text: "Feature 2", "score": 0 },
                { value: "feature3", text: "Feature 3", "score": 0 },
                { value: "feature4", text: "Feature 4", "score": 0 },
            ],
            correctAnswer: []
        };

        // Update question data immutably
        setQuestionData((prevData) => [...prevData, newRankingQuestion]);
    };

    const imagepicker = () => {
        const newRadioQuestion = {
            type: "imagepicker",
            name: `${generateQuestionName(questionData.length)}`, // Ensure unique name based on the current length
            title: "Which animal design do you prefer?", // Title for the question
            isRequired: true,  // Mark the question as required
            showScore: isScoreBased,
            choices: [
                {
                    value: "Image 1",
                    imageLink: "https://surveyjs.io/Content/Images/examples/image-picker/lion.jpg",
                    score: 5 // Initially set score
                },
                {
                    value: "Image 2",
                    imageLink: "https://surveyjs.io/Content/Images/examples/image-picker/giraffe.jpg",
                    score: 4
                },
                {
                    value: "Image 3",
                    imageLink: "https://surveyjs.io/Content/Images/examples/image-picker/panda.jpg",
                    score: 3
                },
                {
                    value: "Image 4",
                    imageLink: "https://surveyjs.io/Content/Images/examples/image-picker/camel.jpg",
                    score: 2
                }
            ],
            imageFit: "cover", // How the images should be displayed
            correctAnswer: ""  // Example of the correct answer (you can change this)
        };

        // Add the new question to the question data
        setQuestionData([...questionData, newRadioQuestion]);
    };

    const handleDuplicateQuestion = (index: number) => {
        const duplicatedQuestion = { ...questionData[index], name: `page${currentPage}-${generateQuestionName(questionData.length)}`, };
        setQuestionData([...questionData, duplicatedQuestion]);
    };

    const addTestfiled = () => {
        // Generate a unique name based on the current length of questionData
        const newCheckboxQuestion = {
            "type": "text",
            "name": `${generateQuestionName(questionData.length)}`,
            "title": `You miss work or school because of your headaches? ${generateQuestionName(questionData.length)}`,
            "inputType": isScoreBased ? "number" : "text",
            "placeholder": "",
            "value": "",
            "isRequired": true,
            "showScore": isScoreBased,
            "validators": isScoreBased ? [
                {
                    "type": "numeric",
                    "text": "The value must be between 0 and 90.",
                    "minValue": 0,
                    "maxValue": 90
                }
            ] : []
        };

        // Add the new question to the existing questionData
        setQuestionData((prevData) => [...prevData, newCheckboxQuestion]);
    };

    const addSwitchboolean = () => {
        const newCheckboxQuestion = {
            type: "boolean",
            name: `${generateQuestionName(questionData.length)}`,
            title: "What features did you like the most?",
            labelTrue: "Yes",
            labelFalse: "No",
            showScore: isScoreBased, // This is where isScoreBased comes into play
            isRequired: true,
            // score: 2,
            "score": { "true": 20, "false": 0 },
            correctAnswer: [],
        };
        console.log("in addSwitchboolean", questionData);
        setQuestionData([...questionData, newCheckboxQuestion]);
    };

    const Radiogroup11 = () => {
        // Define the new question with conditional visibility
        const newCheckboxQuestion = {
            "type": "radiogroup",
            "name": `${generateQuestionName(questionData.length)}`,
            "title": "When you have headaches, how often is the pain severe?",
            "isRequired": true,
            showScore: isScoreBased,
            // Conditionally add the visibleIf property, incrementing based on question index
            // ...(questionIndex > 0 ? { "visibleIf": `{${generateQuestionName(questionIndex - 1)}} notempty` } : {}),
            "choices": [
                { "value": "2", "text": "Never", "score": 2 },
                { "value": "4", "text": "Rarely", "score": 4 },
                { "value": "8", "text": "Sometimes", "score": 8 },
                { "value": "10", "text": "Very Often", "score": 10 },
                { "value": "13", "text": "Always", "score": 13 }
            ]
        }

        // Add the new question to the existing questionData
        setQuestionData((prevData) => [...prevData, newCheckboxQuestion]);
    };

    const Fileuploadmodel = () => {
        const newCheckboxQuestion = {
            type: "file",
            name: `${generateQuestionName(questionData.length)}`,
            title: "Upload a picture of your purchase receipt.",
            isRequired: false,
            showScore: isScoreBased,
            storeDataAsText: true,
            "score": 0,
            maxSize: 102400
        };
        setQuestionData([...questionData, newCheckboxQuestion]);
    }

    useEffect(() => {
        if (!userId) return;

        const fetchData = async () => {

            try {
                // Make the API call
                const result = await submitFormResponseApisuryvegetcall(userId);
                console.log("result", result.data.data)
                setApiResponse(result.data.data)

            } catch (err) {
                console.log(err);
            }
        };

        fetchData();
    }, [userId]);

    const handleSubmit = async () => {
        if (!apiresponse.id) {
            console.error("API response does not have an ID");
            return;
        }

        // Update the state with new questions
        const updatedApiResponse = {
            ...apiresponse,
            questions: allData, // Add or update questions
        };

        setApiResponse(updatedApiResponse);

        try {
            // Make the PUT API call
            const response = await submitFormResponseApisuryveputcall(apiresponse.id, updatedApiResponse);
            console.log("PUT response", response);

            //   message
            // Optionally update the state with the response
            setApiResponse(response.data);
            if (id) {
                // If `id` exists, update the URL
                console.log(`Redirecting to /forms with id: ${id}`);
                window.location.href = `/forms`; // This will reload the page with the new URL
            } else if (UserId && UserId.trim() !== "") {
                // If `UserId` exists and is valid, update the URL
                console.log(`Redirecting to /forms with UserId: ${UserId}`);
                window.location.href = `/forms`; // This will reload the page with the new URL
            } else {
                // If `UserId` is invalid or missing, show an alert
                alert("Invalid or missing UserId!");
            }


            // showSnackbar(response.data.message, "success")
        } catch (err) {
            console.error("Error updating questions", err);
        }
    };

    useEffect(() => {
        console.log("apiresponse structure:", apiresponse);

        // Delay setting data to simulate asynchronous behavior
        const timeoutId = setTimeout(() => {
            if (apiresponse && apiresponse?.questions && apiresponse.questions?.pages) {
                console.log("apiresponse?.questions?.pages", apiresponse?.questions?.pages);
                setAllData({
                    pages: apiresponse?.questions?.pages && apiresponse?.questions?.pages.length > 0 ? apiresponse?.questions?.pages : [
                        {
                            name: "page1",
                            description: "",
                            title: "",
                            elements: [
                                [],
                            ],
                        },
                    ]
                });
            }
            if (apiresponse && apiresponse?.questions && apiresponse?.questions?.pages && apiresponse?.questions?.pages?.[0]?.elements) {
                console.log("Setting questionData:", apiresponse.questions.pages[0].elements);
                setQuestionData(apiresponse.questions.pages[0].elements);
            } else {
                console.log("questions.pages[0].elements is undefined or null");
                setQuestionData([]); // Fallback to an empty array
            }
            setFormDesignerLoading(false);
        }, 1000);

        // Cleanup timeout on component unmount or apiresponse change
        return () => clearTimeout(timeoutId);
    }, [apiresponse]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, field: string) => {
        setAllData((prevState: any) => {
            const updatedPages = [...prevState.pages];
            updatedPages[currentPage - 1] = {
                ...updatedPages[currentPage - 1],
                [field]: event.target.value, // Update description at the specific index
            };
            return {
                ...prevState,
                pages: updatedPages, // Return the updated pages array
            };
        });
    };

    useEffect(() => {
        if (submitTrigger) {
            //   alert("Triggered in child component!");
        }
    }, [submitTrigger]);

    useEffect(() => {
        if (submitTriggerQuestion) {
            handleSubmit();
            setSubmitTriggerQuestion(false);
        }
    }, [submitTriggerQuestion])

    const renderTabContent = () => {
        if (selectedTab === 'Form Designer') {
            return (
                <FormDesigner allData={allData} handleDuplicateQuestion={handleDuplicateQuestion} handleInputChange={handleInputChange} handleOnClickPage={openisonclick} handlePageChange={handlePageChange} handleRemoveQuestion={handleRemoveQuestion} currentPage={currentPage} isonclick={isonclick} duplicatePage={duplicatePage} deletePage={deletePage} questionData={questionData} updateQuestion={updateQuestion} toggleRequired={toggleRequired} addPage={addPage} updateQuestionName={updateQuestionName} loading={formdesignerLoading} />
            );
        } else if (selectedTab === 'JSON Editor') {
            return (
                <>
                    <pre>{JSON.stringify(formJsonData, null, 2)}</pre>
                </>
            )
        }
        else if (selectedTab === 'Preview') {
            return (
                <>
                    <Box sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        width: "100%"
                    }}>
                        <>
                            {questionData && questionData.length > 0 ? (
                                <>
                                    <NewSurveyComponent questionData={formJsonData} from='form-design' />
                                </>
                            ) : (
                                <p>Loading or no data available...</p>
                            )}
                        </>
                    </Box>
                </>
            )
        } else if (selectedTab === 'Form Details') {
            return (
                <>
                    <AddNewFormCompFormid submitTrigger={submitTrigger} setSubmitTrigger={setSubmitTrigger} />
                </>
            )
        } else {
            return null;  // For all other tabs, render nothing
        }
    };

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%"
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', height: "100%" }}>
                {/* Main Content Area */}
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', height: "100%" }} onClick={() => {
                    closeisonclick();
                    setSelectedQuestionType(null);
                }}>
                    <Box sx={{
                        display: 'flex', flexDirection: 'row', flex: 1,
                        height: "100%"
                    }}>
                        {/* Main Content */}
                        <Box sx={{
                            flex: 1,
                            width: "100%",
                            padding: "20px",
                            overflowY: selectedTab === 'Form Designer' ? "hidden" : "auto",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            backgroundColor: "var(--neuro-bg-light-grey-secondary)"

                        }}>
                            {renderTabContent()}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>

    );
};

export default NewFormpagesEdit;
